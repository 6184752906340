/* open-sans-regular - latin */
@font-face {
  font-family: 'opensans';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/open-sans-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
  url('fonts/open-sans-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('fonts/open-sans-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('fonts/open-sans-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('fonts/open-sans-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('fonts/open-sans-v15-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

body {
  font-family: opensans;
}


.legalsContainer {
  margin: 0 auto;
  width: 600px;
  align: left;
  text-align: left;
  margin-top: 100px;
}


a {
  color: #2E4671;
  text-decoration: none;
}

a:hover {
  color: #5CA9DB;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #fcfeff;
  flex-direction: column;
  position: fixed;
  font-size: calc(10px + 2vmin);
  width: 100%;
  top: 0;
  margin: 0;
  padding: 0;
  border: 0;
  height: 100px;
}

.flex-container {
  display: flex;
}

.logo img {
  /*max-height: 58px;*/
  height: 100%;
  float: left;
  position: absolute;
  left: 0;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hero {
  height: 700px;
  background-size: cover;
  align-items: stretch;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hero-body {
  padding-bottom: 18rem;
  padding-top: 18rem;
}

.hero h1 {
  font-weight: bold;
  font-size: 2rem;
  line-height: 1.17;
  color: white;
}

.hero h2 {
  font-size: 1.25rem;
  line-height: 1.23;
  color: white;
  padding: 0 20%;
  font-weight: normal;
}

.section-box {
    text-align: center;
}

.section-box p {
    color: #909090;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
}

.darker {
    background-color: #FAFAFA;
}
