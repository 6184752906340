
.footer {
    width: 100%;
    color: white;
    position: relative;
    margin-top: 41px;
    text-align: left;
    background-color: #454545;
}

.footer .upper a {
    color: white;
}

.footer .bottom {
    text-align: center;
    position: relative;
    display: block;
    color: #161A1C;
    background-color: #E9E9E9;
    height: 55px;
}

.copyright {
    float: left;
    font-size: 13px;
    line-height: 24px;
    padding-top: 10px;
    margin-left: 90px;
}

.links {
    display: inline-block;
    vertical-align: top;
    margin-left: 50px;
}

.links ul {
    list-style: none;
    display: inline-block;
}

.links ul li {
    display: inline-block;
    float: left;
    margin-right: 20px;
}

.links ul li a {
    text-decoration: none;
}

.links ul li a:hover {
    color: #8BCFF1;
}

.socials {
    display: inline-block;
    float: right;
    vertical-align: top;
    margin-right: 90px;
}

.socials ul {
    list-style: none;
    display: inline-block;
}

.socials ul li {
    display: inline-block;
    float: left;
}

.socials ul li a.youtube {
    background: url(https://www.cuba-platform.com//sites/all/themes/cuba_adaptive/img/socials-youtube.png) top center no-repeat;
    opacity: .7;
    -o-opacity: .7;
    -moz-opacity: .7;
}

.socials ul li a.facebook {
    background: url(https://www.cuba-platform.com//sites/all/themes/cuba_adaptive/img/socials-fb-darker.png) top center no-repeat;
    opacity: .7;
    -o-opacity: .7;
    -moz-opacity: .7;
}

.socials ul li a.twitter {
    background: url(https://www.cuba-platform.com//sites/all/themes/cuba_adaptive/img/socials-tw-darker.png) top center no-repeat;
    opacity: .7;
    -o-opacity: .7;
    -moz-opacity: .7;
}

.socials ul li a.linkedin {
    background: url(https://www.cuba-platform.com//sites/all/themes/cuba_adaptive/img/linkedin-icon.png) top center no-repeat;
    opacity: .7;
    -o-opacity: .7;
    -moz-opacity: .7;
}

.socials ul li a.rss {
    background: url(https://www.cuba-platform.com//sites/all/themes/cuba_adaptive/img/rss.png) top center no-repeat;
    opacity: .7;
    -o-opacity: .7;
    -moz-opacity: .7;
}

.socials ul li a {
    width: 18px;
    height: 18px;
    display: inline-block;
    margin-left: 25px;
}