ul.ms-navigation {
    display: table;
    padding: 0;
    height: 40px;
    list-style-type: none;
    text-align: left;
    margin: 20px 30px 0 auto;
}

ul.ms-navigation > li {
    vertical-align: bottom;
}


ul.ms-navigation li {
    display: table-cell;
    margin: 0;
}

ul.ms-navigation > li > a {
    text-decoration: none;
    color: #3c3c3c;
}

ul.ms-navigation > li > a:hover {
    color: #2B4570;
}

ul.ms-navigation > li > a > .font-navigation-menu {
    line-height: 1;
    font-size: 20px;
    margin-right: 40px;
    border-color: red;
    border-width: 1px;
}